import React from "react";

function Ambulance() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_0_441"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_0_441)">
                <path d="M38.524 22.157a.98.98 0 00-.674.98v9.8c0 .542.439.98.98.98h14.7a.979.979 0 00.703-1.677.981.981 0 00-.702-.282H39.81v-7.84h7.534a.979.979 0 00.702-1.678.981.981 0 00-.702-.283H38.83a1.002 1.002 0 00-.306 0z"></path>
                <path d="M3.241 16.278a.98.98 0 00-.673.98v29.401c0 .541.438.98.98.98h6.983a.979.979 0 00.702-1.678.981.981 0 00-.702-.282H4.528V18.238h38.59l7.717 11.944a.99.99 0 00.122.185s.99 1.002 2.022 2.327c1.032 1.325 2.083 2.99 2.327 3.982.328 1.328.074 3.863-.306 5.941-.31 1.7-.547 2.6-.674 3.063h-6.615a.979.979 0 00-.702 1.678c.187.184.44.286.702.282h7.412a.98.98 0 00.919-.735s.52-1.738.918-3.92.735-4.75.245-6.738c-.407-1.65-1.598-3.37-2.695-4.778-1.038-1.332-1.922-2.222-2.021-2.327l-.123-.124-7.901-12.311a.981.981 0 00-.797-.43H3.548a1.002 1.002 0 00-.306 0z"></path>
                <path d="M17.267 44.698c-2.648 0-4.9 2.254-4.9 4.9 0 2.648 2.252 4.9 4.9 4.9 2.646 0 4.9-2.252 4.9-4.9 0-2.646-2.254-4.9-4.9-4.9zm0 1.96c1.458 0 2.94 1.482 2.94 2.94 0 1.459-1.482 2.94-2.94 2.94-1.459 0-2.94-1.481-2.94-2.94 0-1.458 1.481-2.94 2.94-2.94zM40.792 44.698c-2.647 0-4.9 2.254-4.9 4.9 0 2.648 2.253 4.9 4.9 4.9 2.647 0 4.9-2.252 4.9-4.9 0-2.646-2.253-4.9-4.9-4.9zm0 1.96c1.458 0 2.94 1.482 2.94 2.94 0 1.459-1.482 2.94-2.94 2.94-1.459 0-2.94-1.481-2.94-2.94 0-1.458 1.482-2.94 2.94-2.94zM38.462 13.765a2.953 2.953 0 00-3.246.061 2.9 2.9 0 00-1.225 3.002l1.898-.429c-.067-.322.144-.787.43-.98.284-.192.81-.184 1.102 0 .29.184.487.656.429.98l1.898.368c.21-1.165-.287-2.367-1.286-3.002zM36.567 6.477a.98.98 0 00-.674.98v2.88a.98.98 0 101.96 0v-2.88a.98.98 0 00-1.286-.98zM42.443 8.438a.98.98 0 00-.367.245l-1.96 1.96a.98.98 0 101.347 1.409l1.96-1.96a.98.98 0 00-.98-1.654zM19.224 22.156c-4.7 0-8.82 4.121-8.82 8.82 0 4.7 4.12 8.821 8.82 8.821s8.82-4.12 8.82-8.82-4.12-8.82-8.82-8.82zm0 1.96c3.51 0 6.86 3.35 6.86 6.86 0 3.511-3.35 6.861-6.86 6.861-3.51 0-6.86-3.35-6.86-6.86 0-3.51 3.35-6.86 6.86-6.86z"></path>
                <path d="M18.924 26.077a.98.98 0 00-.674.98v7.78a.98.98 0 101.96 0v-7.78a.979.979 0 00-1.286-.98z"></path>
                <path d="M15.007 29.998a.991.991 0 00.306 1.96h7.84a.98.98 0 100-1.96h-7.84a.971.971 0 00-.306 0zM30.686 8.437a.98.98 0 00-.368 1.654l1.96 1.96a.98.98 0 101.348-1.409l-1.96-1.96a.981.981 0 00-.98-.245zM23.825 45.68a.991.991 0 10.306 1.96h9.8a.981.981 0 100-1.96h-9.8a.97.97 0 00-.306 0zM41.772 49.575a.98.98 0 11-1.96 0 .98.98 0 011.96 0zM18.252 49.575a.981.981 0 11-1.962-.001.981.981 0 011.962 0z"></path>
            </g>
        </svg>
    );
}

export default Ambulance;
