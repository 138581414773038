import React from "react";

function Wallet() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_0_434"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_0_434)">
                <path d="M17.812 35.73c-2-.683-2.809-1.081-2.735-2.297.072-1.193.585-1.506.753-1.612 1.21-.739 3.752.082 4.55.43a1 1 0 00.803-1.834 12.038 12.038 0 00-2.996-.816v-2.249a1.002 1.002 0 00-2.003 0v2.28c-.49.087-.969.221-1.398.482-.718.438-1.597 1.35-1.71 3.2-.172 2.86 2.291 3.7 4.087 4.312 1.818.62 2.615.994 2.615 1.966 0 1.043-.286 1.748-.847 2.096-.947.583-2.777.299-4.893-.758a1.003 1.003 0 00-.895 1.792c1.085.542 2.1.89 3.043 1.085v2.358a1.002 1.002 0 002.003 0v-2.242c.668-.064 1.287-.216 1.796-.535.819-.506 1.796-1.576 1.796-3.796 0-2.509-2.293-3.29-3.969-3.863z"></path>
                <path d="M54.294 29.027h-.84V18.378a1 1 0 00-1-1h-9.98V5.553a1 1 0 00-1.316-.95S5.493 16.486 2.608 17.427c-.408.133-.686.4-.686.95v36.764a1 1 0 001.001 1.001h49.53a1 1 0 001.002-1V44.492h.839a3.57 3.57 0 003.566-3.566V32.59a3.57 3.57 0 00-3.565-3.564h-.001zM40.47 6.943v10.434H9.104L40.47 6.943zm10.982 47.198H3.922V19.377h47.529v9.647H36.099a3.57 3.57 0 00-3.566 3.565v8.335a3.57 3.57 0 003.566 3.566h15.352v9.651h.001zm4.405-13.215c0 .865-.703 1.564-1.563 1.564H36.1c-.863 0-1.563-.701-1.563-1.564V32.59c0-.863.702-1.562 1.563-1.562h18.192c.863 0 1.564.7 1.564 1.562v8.336z"></path>
                <path d="M40.657 33.006a3.758 3.758 0 00-3.755 3.754 3.758 3.758 0 003.755 3.754 3.76 3.76 0 003.754-3.754 3.76 3.76 0 00-3.754-3.754zm0 5.506a1.756 1.756 0 01-1.752-1.752c0-.965.787-1.752 1.752-1.752.964 0 1.752.787 1.752 1.752s-.787 1.752-1.752 1.752z"></path>
            </g>
        </svg>
    );
}

export default Wallet;
