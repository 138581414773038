import React from "react";

function Pain() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_0_427"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g fill="#fff" mask="url(#mask0_0_427)">
                <path d="M56.484 29.763c0-3.034-1.283-5.95-3.52-8.002l-5.377-4.93a5.04 5.04 0 00-7.011.171 5.014 5.014 0 00-1.363 2.563c-1.684-3.319-5.123-5.604-9.092-5.604-3.97 0-7.408 2.285-9.093 5.603a5.021 5.021 0 00-1.361-2.562 5.043 5.043 0 00-7.012-.17L7.277 21.76a10.881 10.881 0 00-3.52 8.002c0 4.774 3.063 8.938 7.619 10.361l3.75 1.172v14.839c0 .596.483 1.08 1.08 1.08h27.83a1.08 1.08 0 001.08-1.08V41.297l3.75-1.172a10.81 10.81 0 007.619-10.362zM40.64 24.182l6.55 6.382-3.4 1.635h-7.416c2.396-1.868 3.945-4.773 3.945-8.039 0-.129-.015-.254-.02-.381.113.135.213.279.34.403zm-10.52-8.06c4.433 0 8.04 3.606 8.04 8.039S34.552 32.2 30.12 32.2c-4.433 0-8.039-3.606-8.039-8.04 0-4.432 3.606-8.038 8.04-8.038zm-10.18 7.656c-.004.128-.019.253-.019.383 0 3.266 1.55 6.17 3.945 8.039H16.45l-3.401-1.636 6.55-6.382c.128-.124.228-.269.34-.404zm28.282 14.285l-4.509 1.41a1.08 1.08 0 00-.758 1.03v14.552h-25.67V40.503c0-.472-.306-.89-.758-1.03l-4.509-1.41a8.657 8.657 0 01-6.101-8.3 8.717 8.717 0 012.82-6.41l5.377-4.928a2.889 2.889 0 014.016.097c.548.554.846 1.287.839 2.066a2.874 2.874 0 01-.877 2.047l-7.656 7.46a1.082 1.082 0 00.286 1.748l5.015 2.41c.146.07.306.107.468.107h27.83c.163 0 .322-.036.469-.106l5.014-2.411a1.082 1.082 0 00.285-1.748l-7.655-7.46a2.874 2.874 0 01-.876-2.049 2.876 2.876 0 01.838-2.065 2.887 2.887 0 014.017-.097l5.376 4.93a8.719 8.719 0 012.82 6.41 8.656 8.656 0 01-6.1 8.3zM29.041 4.295v4.619a1.08 1.08 0 102.16 0V4.295a1.08 1.08 0 10-2.16 0zM19.25 7.498l2.309 4a1.08 1.08 0 101.87-1.08l-2.309-4a1.081 1.081 0 00-1.87 1.08zM39.117 6.416l-2.31 4a1.08 1.08 0 001.871 1.08l2.31-4a1.08 1.08 0 10-1.871-1.08z"></path>
            </g>
        </svg>
    );
}

export default Pain;
