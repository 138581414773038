import React from "react";

function House() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_0_459"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g mask="url(#mask0_0_459)">
                <path
                    fill="#fff"
                    d="M7.467 30.623v25.865c0 .613.497 1.11 1.11 1.11h42.589c.613 0 1.11-.496 1.11-1.11V30.63c.484.22 1.009.35 1.55.35a3.776 3.776 0 002.82-1.267 3.775 3.775 0 00-.313-5.323L32.377 3.095a3.773 3.773 0 00-5.012 0L15.008 14.078V7.244a1.108 1.108 0 00-1.378-1.076l-5.324 1.33c-.494.124-.84.568-.84 1.077v11.978c0 .069.027.129.039.194L3.407 24.39a3.776 3.776 0 00-.312 5.324c1.121 1.26 2.905 1.57 4.37.908h.002zm16.859 24.756v-8.274a5.485 5.485 0 015.478-5.479h.134a5.484 5.484 0 015.479 5.48v8.273H24.325zm13.309 0v-8.274c0-4.244-3.453-7.697-7.697-7.697h-.134c-4.244 0-7.697 3.453-7.697 7.697v8.274H9.685v-8l9.446-3.148a1.11 1.11 0 00.6-1.623l-2.881-4.803 3.824-3.824a1.109 1.109 0 10-1.569-1.568l-4.436 4.436c-.36.36-.429.919-.167 1.355l2.616 4.36-7.433 2.478v-16.14l20.186-17.944 20.186 17.944v15.15l-3.646-3.646-2.147-9.667a1.11 1.11 0 00-1.083-.868h-4.794l-1.94-4.849a1.11 1.11 0 00-2.06.825l2.219 5.545c.169.42.576.697 1.03.697h4.655l2.026 9.114c.045.206.149.394.298.543l5.443 5.443v8.192l-12.423-.002zM9.685 9.44l3.106-.775v7.385l-3.106 2.76V9.44zM4.883 26.048l9.757-8.674c.02-.018.042-.034.061-.054L28.84 4.751a1.553 1.553 0 012.063 0L54.86 26.046c.64.569.698 1.552.13 2.191-.548.615-1.576.678-2.194.129L30.608 8.644a1.107 1.107 0 00-1.473 0L6.948 28.366c-.641.57-1.626.51-2.194-.128a1.554 1.554 0 01.128-2.191z"
                ></path>
            </g>
        </svg>
    );
}

export default House;
